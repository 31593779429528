/*!
* Modal code
*/
// document.addEventListener("DOMContentLoaded", () => {
  this.bsToggles = document.querySelectorAll('[data-bs-toggle="modal"]');
  for (var i = 0; i < this.bsToggles.length; i++) {
    this.bsToggle = this.bsToggles[i];
    this.bsToggle.addEventListener("click", (e) => {
      e.target.classList.add('btn-spinner');
      import(
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackChunkName: "modal/modal" */
        './../components/modal/modal.js'
      ).then(modalService => {
        var modal = new modalService.default();
        modal.bsToggle = this.bsToggle;
        modal.initModal(e);
        var bsModal = document.getElementById('modal');
        bsModal.addEventListener('hide.bs.modal', (evnt) => {
          debugger;
          if(this.bsToggle) {
            e.target.classList.remove('btn-spinner');
          }
        });
      });
    });
  }

  const hsforms = document.querySelectorAll(".embedhsform");
  if(hsforms.length) {
    import(
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackChunkName: "modal/modal" */
      './../components/modal/modal.js'
    ).then(modalService => {
      var modal = new modalService.default();
      modal.loadInlineForm(hsforms);
    });
  }
// });

// import(
//   /* webpackMode: "lazy" */
//   /* webpackPrefetch: true */
//   /* webpackChunkName: "modal/modal" */
//   './../components/modal/modal.js'
// ).then(modalService => {
//   var modal = new modalService.default();
//   modal.setData({type:'default', size:'modal-lg'});
//   modal.getModal('10-questions-guide-modal');
//   // myExitInModal.addEventListener('hidden.bs.modal', (e) => {
//   // })
// });